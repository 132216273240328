/**************************************************
 * Nombre:       Tarjeta_Categoria
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import {theme} from "../../Tema";
import {useNavigate} from "react-router-dom";

const Tarjeta_Categoria = ({item}) => {
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const sTab = useMediaQuery(theme.breakpoints.only('sm'))
    const navigate = useNavigate()


    const direccionar = () => {
        setTimeout(() => {
            navigate('/Portafolio/' + item.nombre)
        }, 100)
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{marginTop: 4}}
        >


            <Grid item container lg={12} sm={12} xs={12}
                  sx={{
                      justifyContent: 'flex-start',
                      borderTopLeftRadius: 30,
                      borderTopRightRadius: 30,
                      '&:hover': {marginTop: -2, boxShadow: 8}
                  }}>
                <ButtonBase
                    onClick={() => direccionar()}
                    sx={{
                        width: '100%',
                        borderTopLeftRadius: 30,
                        borderTopRightRadius: 30,
                    }}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        sx={{
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundImage: `url(${item.img_larga})`,
                            height: masSM ? 380 : 240,
                            borderTopLeftRadius: 30,
                            borderTopRightRadius: 30,
                            boxShadow: 4,
                            width: 194
                        }}
                    />
                </ButtonBase>

            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 2}}>
                <Typography
                    sx={{color: theme.palette.secondary.main, fontSize: 20, fontWeight: 500}}>{item.nombre}</Typography>
            </Grid>


        </Grid>
    )

}
export default Tarjeta_Categoria