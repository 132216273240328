/**************************************************
 * Nombre:       Seccion_Categorias
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     12 Hr
 **************************************************/
import {Button, Grid, useMediaQuery} from "@mui/material";
import {useObtenerColleccionTabla} from "../../Servicios/BD/useObtenerColleccionTabla";
import {useEffect, useState} from "react";
import {useDialogo} from "../../Modulo_dialogos/Hooks/useDialogo";
import {Flag, Group, LocalMall, NoteAltOutlined} from "@mui/icons-material";
import {contarPropiedad} from "../../Servicios/BD/Contadores/contarPropiedad";
import TituloNotificaciones from "../../Modulo_dashboard/ComponentesSecundarios/TituloNotificaciones";
import TablaBasica from "../../Modulo_tablas/Componentes/TablaBasica";
import Dialogo_Producto from "../Dialogos/Dialogo_Producto";
import Indicadores from "../../Modulo_Graficas/Componentes/Indicadores/Indicadores";
import {contarColeccion} from "../../Servicios/BD/Contadores/contarColeccion";
import {theme} from "../../Tema";
import {CATEGORIAS} from "../../Constantes";
import Dialogo_Categoria from "../Dialogos/Dialogo_Categoria";
import Dialogo_Anuncio from "../Dialogos/Dialogo_Anuncio";
import Dialogo_Artesano from "../Dialogos/Dialogo_Artesano";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import Formulario_Descripcion_Catalogo from "../Formularios/Formulario_Descripcion_Catalogo";
import {LoadingButton} from "@mui/lab";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";

const Seccion_Textos = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [cargando, setCargando] = useState(false)
    const {props, obtenerEntidad} = useFormulario({valoresDefecto: {}})

    const guardarCambios = () => {
        obtenerEntidad().then((entidad) => {
            setCargando(true)
            let obj = entidad
            obj.id = 'descripcionCatalogo'
            guardarDoc('textos', obj).then((dox) => {
                if (dox.res) {
                    alert('cambios guardados con exito')
                } else {
                    alert('Ups, algo inesperado paso, por favor intentalo nuevamente')
                }
                setCargando(false)
            })
        })

    }

    const botones = []


    useEffect(() => {


    }, []);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <TituloNotificaciones titulo={'Textos'} textoComplementario={''}
                                      textoResaltado={``} botones={botones}/>
            </Grid>

            <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                        <Formulario_Descripcion_Catalogo props={props}/>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12}
                          sx={{justifyContent: 'flex-start', px: 1, marginTop: 2}}>
                        <LoadingButton
                            variant={'contained'}
                            loading={cargando}
                            onClick={() => guardarCambios()}
                        >
                            Guardar Cambios
                        </LoadingButton>
                    </Grid>

                </Grid>

            </Grid>


        </Grid>
    )

}
export default Seccion_Textos



