/**************************************************
 * Nombre:       Testimonios
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, useMediaQuery} from "@mui/material";
import Tarjeta_testimonio from "./Tarjeta_testimonio";
import {Splide, SplideSlide} from "@splidejs/react-splide";
import test_natali from '../../Recursos/test_natali.png'
import test_diana from '../../Recursos/testi_diana.png'
import test_carolina from '../../Recursos/test_carolina.png'
import {theme} from "../../Tema";

const Testimonios = () => {
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item container lg={10} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Splide aria-label="My Favorite Images" style={{width: "100%"}}
                            options={{
                                autoplay: true,
                                speed: 3000,
                                rewind: true,
                                perPage: masSM ? 2 : 1,
                                arrows: false,
                                gap: 80,
                                pagination: false,
                                drag: false,
                                noDrag: true

                            }}
                    >
                        {testis.map((item, index) => {
                            return (
                                <SplideSlide key={`dwr${index}`}>
                                    <Tarjeta_testimonio item={item}/>
                                </SplideSlide>
                            )
                        })}


                    </Splide>
                </Grid>


            </Grid>


        </Grid>
    )

}
export default Testimonios

const testis = [
    {
        nombre: 'Carolina Duran',
        descripcion: 'Excelente lugar para comprar recuerdos de Pasto,  10 de atención al cliente, desde hermosas agendas hasta tiernos guantes de lana. Mil gracias, lo recomiendo',
        img: test_carolina
    },
    {
        nombre: 'Natali Morales',
        descripcion: 'Un excelente lugar para comprar los mejores recuerdos de Nariño! Hermosos y bién elaborados, recomiendo 100% a Nariño Souvenirs, los mejores :)…',
        img: test_natali
    },
    {
        nombre: 'Diana Guevara Fotografía',
        descripcion: 'Nariño Souvenirs es la mejor tienda de productos nariñenses. Su ubicación en la Plaza de Nariño hace del local un punto imperdible en una visita a Pasto. 100% recomendado…',
        img: test_diana
    }
]