import React, {createContext, useEffect, useState} from 'react';
import './App.css';
import {Grid} from "@mui/material";
import Dashboard from "./Dashboard/Dashboard";
import {useEscucharAuth} from "./Servicios/Auth/useEscucharAuth";
import {useEscucharUsuarioToCorreo} from "./Servicios/BD/useEscucharUsuarioToCorreo";
import {iniciarAnaliticas} from "./Servicios/Analiticas/iniciarAnaliticas";
import Pagina from "./Pagina/Pagina";
import '@fontsource/kanit/400.css'
import '@fontsource/kanit/500.css'
import '@fontsource/kanit/700.css'
import '@fontsource/kanit/400.css'
import '@fontsource/kanit/800.css'
import {Helmet} from "react-helmet";
import {useObtenerToken} from "./M_Notificaciones/Hooks/useObtenerToken";
import {actualizarDoc} from "./Servicios/BD/actualizarDoc";
import {useEscucharNotificaion} from "./M_Notificaciones/Hooks/useEscucharNotificacion";
import {funSuscribirTopic} from "./M_Notificaciones/Funciones/funSuscribirTopic";


export const usuarioContext = createContext();

function App() {
    const {email} = useEscucharAuth()
    const {usuario} = useEscucharUsuarioToCorreo({correo: email})
    const [mesa, setMesa] = useState(0)
    const [puesto, setPuesto] = useState('none')
    const {token} = useObtenerToken({ApiKey: "BOKoBv71FXi3IL4e_iqj09p9g84kqiCSCr98l1u_XTSdMgO0ApOYBY_eLtGOWLhR9LPI2TWWHQc0AXgfcCfWkpQ"})
    const {Notificacion} = useEscucharNotificaion()


    const valoresProvider = {
        usuario,
        mesa,
        setMesa,
        puesto,
        setPuesto
    }

    const {Provider} = usuarioContext;


    useEffect(() => {

        iniciarAnaliticas()

        if (window.swUpdateReady) {
            window.swUpdateReady = false;
            window.stop();
            window.location.reload();
        }


    }, [])

    useEffect(() => {


        if (token !== null && usuario) {

            if (token !== usuario.token) {

                funSuscribirTopic(token, 'admin', 'AAAAb7L8yak:APA91bG_XP9d7S_bk_jmszcWDZPj6jD7rTqBxsDR9_-OeYMBsq3KOT16oj8SYVOT3FNQ7yTCQhT16TXow37-WR0pqvk5PCv-FteZl_QkqKSQVRVJydNiLLrG_O1yXXZ4LoIO9HpI2GZb')


            }


        }
    }, [token, usuario])

    return (
        <>
            <Helmet>
                <title>{"Nariño Souvenirs | Artesanias y Recuerdos de Nariño"} </title>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
                />
            </Helmet>


            <h1 style={{display: 'none'}}>Recuerdos de Nariño</h1>
            <h2 style={{display: 'none'}}>Recuerdos de Pasto</h2>
            <h2 style={{display: 'none'}}>Artesanias de Nariño</h2>
            <h2 style={{display: 'none'}}>Artesanias Pasto</h2>
            <h2 style={{display: 'none'}}>Tienda de recuerdo Pasto</h2>
            <h2 style={{display: 'none'}}>Tienda de artesanias Pasto</h2>
            <h2 style={{display: 'none'}}>Tienda de artesanias Coombianas</h2>


            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{maxWidth: '100%'}}
            >


                <Provider value={valoresProvider}>
                    {usuario ?
                        <Dashboard/>
                        :
                        <Pagina/>
                    }

                </Provider>

                <Notificacion/>

            </Grid>
        </>
    );
}

export default App;
