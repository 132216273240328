/**************************************************
 * Nombre:       Anuncios
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, IconButton, Typography, useMediaQuery} from "@mui/material";
import {theme} from "../../Tema";
import fondo from '../../Recursos/fondo_anuncios.svg'
import personaje from '../../Recursos/personaje.png'
import {ArrowDownward} from "@mui/icons-material";
import {scrolearAComponente} from "../../Utilidades/scrolearAComponente";

const Categoria_Detalle = ({categoria}) => {
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const sTab = useMediaQuery(theme.breakpoints.only('sm'))


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item container lg={10} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                        sx={{
                            backgroundColor: theme.palette.primary.main,
                            backgroundImage: `url(${fondo})`,
                            p: 6,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'center',
                            backgroundPosition: 'center',
                            pl: 7
                        }}
                        spacing={masSM ? 2 : 0}

                    >

                        <Grid item container lg={5} sm={5} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <img src={categoria.img} width={'124%'} height={'auto'}
                                 style={{
                                     marginLeft: sCell ? -30 : -120,
                                     marginTop: sCell ? -100 : -75,
                                     marginBottom: sCell ? 0 : -150,
                                     boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)"
                                 }}/>
                        </Grid>

                        <Grid item container lg={6} sm={6} xs={12}
                              sx={{justifyContent: 'flex-end', marginTop: sCell ? 3 : 0}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                sx={{px: sCell ? 0 : 2}}
                            >


                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                    <Typography sx={{
                                        color: '#fff',
                                        fontSize: 36,
                                        fontWeight: 800,
                                        lineHeight: 1.1,
                                        textAlign: 'center',
                                    }}>
                                        {categoria.nombre}
                                    </Typography>
                                </Grid>


                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 2, zIndex: 2}}>
                                    <Typography sx={{
                                        color: '#fff', fontSize: 16, fontWeight: 400, display: '-webkit-box',
                                        overflow: 'hidden',
                                        WebkitBoxOrient: 'vertical',
                                        WebkitLineClamp: 3,
                                        textOverflow: "ellipsis",
                                    }}>
                                        {categoria.descripcion}
                                    </Typography>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 4}}>
                                    <IconButton
                                        onClick={() => scrolearAComponente('lista')}
                                        sx={{backgroundColor: '#fff'}}>
                                        <ArrowDownward sx={{fill: theme.palette.primary.main}}/>
                                    </IconButton>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-end', zIndex: 1}}>
                                    {!sTab &&
                                        <img src={personaje} width={220} height={'auto'}
                                             style={{
                                                 marginBottom: -48,
                                                 marginTop: -120,
                                                 marginRight: sCell ? -49 : -108
                                             }}/>
                                    }
                                </Grid>

                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>


            </Grid>

        </Grid>
    )

}
export default Categoria_Detalle