import {FacebookOutlined, Instagram, WhatsApp, WhatshotOutlined} from "@mui/icons-material";

export const SECCIONES = ['Anuncios', 'Portafolio', 'Contactos', 'Testimonios']
export const CATEGORIAS = ['Souvenirs', 'Joyería', 'Artesanía', 'Prendas de vestir', 'Cacao y Café de Nariño', 'Fotografía', 'Libros']

export const ESTADOS = ['nuevo', 'empacado', 'despachado', 'contactado']

export const ESTADOSPAGO = ['Aceptada', 'Rechazada', 'Pendiente', 'Fallida', 'Cancelada']

export const REDES = [
    {
        nombre: 'whatsapp',
        icono: WhatsApp,
        url: 'https://api.whatsapp.com/send?phone=573183270023&text=Hola!'
    },
    {
        nombre: 'facebook',
        icono: FacebookOutlined,
        url: 'https://www.facebook.com/search/top?q=nari%C3%B1o%20souvenirs'
    },
    {
        nombre: 'instagram',
        icono: Instagram,
        url: 'https://www.instagram.com/narinosouvenirs/'
    },

]

export const LINKMAP = "https://www.google.com/maps/dir/?api=1&destination=1.2150014,-77.2786399,20.88&dir_action=navigate"