/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {AccessAlarms, FacebookOutlined, Flag, Instagram, Title} from "@mui/icons-material";
import IngresoImagenCrop from "../../Modulo_formularios/Componentes/IngresoImagenCrop";


const Formulario_Artesano = ({...props}) => {

    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{p: 1}}
            >


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoImagenCrop ancho={3} alto={2} dato={'img'} {...props}/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: -3}}>
                    <Typography>Imagen 3x2</Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Title} nombre={'Titulo de publicacion'} dato={'titulo'}  {...props}
                                  requerido={'El titulo es requerido*'}
                    />
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Flag} nombre={'Nombre'} dato={'nombre'}  {...props}
                                  requerido={'El nombre de la categoria es requerido*'}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto nombre={'Motivacion del artesano'} dato={'descripcion'}  {...props}
                                  requerido={'La descripcion es requerida*'} lineas={4}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={FacebookOutlined} nombre={'Link de facebook'} dato={'facebook'}  {...props}

                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Instagram} nombre={'Link de instagram'} dato={'instagram'}  {...props}

                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={AccessAlarms} nombre={'Link de Tiktok'} dato={'tiktok'}  {...props}

                    />
                </Grid>


            </Grid>
        </form>

    )

}
export default Formulario_Artesano