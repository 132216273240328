/**************************************************
 * Nombre:       Tarjeta_Producto_Detalle
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Box, Button, ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import indicador from '../../Recursos/indicador.svg'
import {theme} from "../../Tema";
import {irUrl} from "../../Utilidades/irUrl";
import {useLocation, useNavigate} from "react-router-dom";
import {formatoMoneda} from "../../Utilidades/formatoMoneda";
import {useCarritoCompras} from "../../Modulo_carrito_compras/Hooks/useCarritoCompras";
import {useFuncionesCarrito} from "../../Modulo_carrito_compras/Hooks/useFuncionesCarrito";
import {ArrowLeft, ArrowLeftOutlined, KeyboardArrowLeft, ShoppingCart, TaskAlt} from "@mui/icons-material";
import {obtenerSeccion} from "../../Utilidades/obtenerSeccion";

const Tarjeta_Producto_Detalle = ({item}) => {
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const sTab = useMediaQuery(theme.breakpoints.only('sm'))
    const navigate = useNavigate()
    const {adicionarProducto, verificarProductoCarrito} = useFuncionesCarrito()
    const {abrir, DialogoCarrito} = useCarritoCompras()
    const location = useLocation()
    return (
        <Grid
            container
            direction="row-reverse"
            justifyContent="flex-start"
            alignItems="center"
            sx={{py: 10, pb: 20}}
        >


            <DialogoCarrito/>
            {obtenerSeccion(location.pathname) === 'Detalle' &&
                <Grid item container lg={12} sm={12} xs={12}
                      sx={{justifyContent: 'flex-start', marginTop: -4, marginBottom: 4}}>
                    <Button
                        onClick={() => window.history.back()}
                        variant={'text'} startIcon={<KeyboardArrowLeft/>}
                        sx={{px: 0, color: '#00000090', marginLeft: masSM ? -6 : 0, py: 0}}
                    >Atras</Button>
                </Grid>
            }

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end', marginBottom: -6}}>
                {item.descuento > 0 &&
                    <Box sx={{backgroundColor: theme.palette.primary.main, px: 2, py: 1, borderRadius: 2}}>
                        <Typography sx={{color: '#fff', fontSize: 24}}>
                            {`- ${item.descuento}%`}
                        </Typography>
                    </Box>
                }
            </Grid>
            <Grid item container lg={4} sm={5} xs={12} sx={{justifyContent: 'center', marginTop: sCell ? -2 : 0}}>
                {item &&
                    <img src={item && item.formato === '1:1' ? item.img : item.img_3x2}
                         width={item && item.formato === '1:1' ? '100%' : '90%'} height={'auto'}
                         style={{marginTop: item.formato === '1:1' ? -6 : 0}}

                    />
                }
            </Grid>

            <Grid item container lg={8} sm={7} xs={12} sx={{justifyContent: 'flex-start'}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Grid item container lg={12} sm={12} xs={12}
                          sx={{justifyContent: 'flex-start', marginLeft: masSM ? -6 : 0, marginTop: sCell ? 4 : 0}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >

                            <Grid item container lg={1} sm={1} xs={1}
                                  sx={{justifyContent: 'flex-start', marginTop: sCell ? 1 : sTab ? 1.2 : 2}}>
                                <img src={indicador} width={sTab ? 24 : 30}
                                     height={sTab ? 24 : 30}/>
                            </Grid>

                            <Grid item container lg={11} sm={11} xs={11} sx={{justifyContent: 'flex-start'}}>
                                <Typography sx={{
                                    fontSize: masSM ? 48 : 36,
                                    color: '#124F46',
                                    fontWeight: 700,
                                    textAlign: sCell ? 'center' : 'left',
                                    lineHeight: 1.1,
                                    display: '-webkit-box',
                                    overflow: 'hidden',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 3,
                                    textOverflow: "ellipsis",
                                }}>
                                    {item && item.nombre}</Typography>
                            </Grid>
                        </Grid>

                    </Grid>


                    <Grid item container lg={10} sm={12} xs={12}
                          sx={{justifyContent: 'flex-start', marginTop: sCell ? 3 : 2}}>
                        <Typography sx={{
                            fontSize: 18, fontWeight: 400, textAlign: sCell ? 'center' : 'left',
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 4,
                            textOverflow: "ellipsis",
                        }}>
                            {item && item.descripcion}
                        </Typography>
                    </Grid>

                    <Grid item container lg={9} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>
                        {item && item.artesano &&
                            <Typography sx={{fontSize: 18, fontWeight: 400}}>creación de: <span
                            >
                                <ButtonBase
                                    onClick={() => irUrl(`https://www.instagram.com/${item.artesano}/`)}
                                    sx={{
                                        fontWeight: 600,
                                        textDecoration: 'underline',
                                        fontSize: 18
                                    }}
                                >
                                     {`@${item.artesano}`}
                                </ButtonBase>
                               </span></Typography>
                        }
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 3}}>
                        {item.descuento > 0 &&
                            <Typography sx={{
                                fontSize: 16,
                                fontWeight: 400,
                                color: '#00000090'
                            }}>{`Antes: ${formatoMoneda(item.valor)}`}</Typography>

                        }
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: -1}}>
                        <Typography sx={{
                            fontSize: 36,
                            fontWeight: 600
                        }}>{item.descuento > 0 ? formatoMoneda(item.rebajado) : formatoMoneda(item.valor)}</Typography>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>

                        {verificarProductoCarrito(item) ?

                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                            >

                                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                                    <Typography sx={{
                                        fontSize: 18,
                                        color: '#00000080',
                                        fontWeight: 600
                                    }}>{'El producto ya se encuentra en el carrito de compras'}</Typography>
                                </Grid>

                                <Grid item container lg={6} sm={6} xs={6}
                                      sx={{justifyContent: 'flex-start', marginTop: 0}}>
                                    <Button
                                        color={'primary'}
                                        onClick={() => abrir()}
                                        startIcon={<TaskAlt/>}
                                        sx={{px: 3, py: sCell ? 2 : 1.5}} fullWidth={sCell}>
                                        Finalizar la compra
                                    </Button>
                                </Grid>

                            </Grid>


                            :
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={2}
                            >


                                <Grid item container lg={5} sm={6} xs={12} sx={{justifyContent: 'flex-start'}}>
                                    <Button
                                        color={'primary'}
                                        onClick={() => {
                                            adicionarProducto(item)
                                            abrir()
                                        }}
                                        startIcon={<TaskAlt/>}
                                        sx={{px: 6, py: sCell ? 2 : 1.5}} fullWidth={sCell}>
                                        Comprar Ahora
                                    </Button>
                                </Grid>


                                <Grid item container lg={5} sm={6} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: sCell ? 2 : 0}}>
                                    <Button
                                        color={'info'}
                                        onClick={() => adicionarProducto(item)}
                                        startIcon={<ShoppingCart/>}
                                        fullWidth={sCell}
                                        sx={{color: theme.palette.primary.main, px: 5, py: sCell ? 2 : 1.5}}>
                                        Agregar al Carrito
                                    </Button>
                                </Grid>


                            </Grid>

                        }


                    </Grid>


                </Grid>
            </Grid>


        </Grid>
    )

}
export default Tarjeta_Producto_Detalle    