/**************************************************
 * Nombre:       BotonPagos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid} from "@mui/material";
import {createRef, useEffect, useRef, useState} from "react";
import btn from '../Recursos/btn_transparente.svg'
import {formatoMoneda} from "../Utilidades/formatoMoneda";
import {TaskAlt} from "@mui/icons-material";

const BotonPagos = ({valor = 0, idPedido}) => {
    const [num, setNum] = useState(0)
    const [open, setOpen] = useState(false)
    const hiddenFileInput = createRef(null);

    const key = "f51d79304fe54456ce887d0cd5856e76"

    useEffect(() => {


        const script = document.createElement("script");
        script.src = "https://checkout.epayco.co/checkout.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {

            document.body.removeChild(script);
        };


    }, [open]);
    return (
        <>


            <form>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                >

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', zIndex: 2}}>
                        <script
                            ref={hiddenFileInput}
                            src="https://checkout.epayco.co/checkout.js"
                            className="epayco-button"
                            data-epayco-key={key}
                            data-epayco-amount={valor}
                            data-epayco-name={"Pedido Narino Souvenirs"}
                            data-epayco-description={"Pedido Narino Souvenirs"}
                            data-epayco-currency="cop"
                            data-epayco-country="co"
                            data-epayco-lang={"es"}
                            data-epayco-extra1={idPedido}
                            //data-epayco-extra2={"cita.placa"}
                            //data-epayco-extra3={"getFechaCompleto(cita.startAt)"}
                            //data-epayco-extra4={"getHora(cita.startAt)"}
                            data-epayco-test="false"
                            data-epayco-autoclick='false'
                            data-epayco-external="false"
                            // envia a direcciones https unicamente
                            data-epayco-response={"https://narinosouvenirs.com/"}
                            data-epayco-button={btn}
                        >

                        </script>

                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12}
                          sx={{justifyContent: 'center', zIndex: 1, marginTop: -6}}>
                        <Button startIcon={<TaskAlt/>}>{`pagar: ${formatoMoneda(valor)}`}</Button>
                    </Grid>

                </Grid>


            </form>


        </>

    );
};

export default BotonPagos;