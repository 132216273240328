/**************************************************
 * Nombre:       Tarjeta_Categoria
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import {theme} from "../../Tema";

const Tarjeta_Categoria_Cuadrada = ({item, click}) => {
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))

    return (

        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            onClick={() => click(item)}
            // sx={{width: masSM ? 245 : 172, m: 1}}

        >

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <ButtonBase
                    sx={{width: '100%'}}
                >
                    <Grid item container lg={12} sm={12} xs={12}
                          sx={{
                              justifyContent: 'center',
                              backgroundImage: `url(${item.img})`,
                              backgroundPosition: 'center',
                              backgroundSize: 'cover',
                              backgroundRepeat: 'no-repeat',
                              height: masSM ? 150 : 100,
                              borderRadius: 4,
                              boxShadow: 4,
                              alignItems: 'flex-end',
                              filter: 'brightness(0.7)',
                              zIndex: 1,
                              width: '100%'
                          }}>

                    </Grid>
                </ButtonBase>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: -5, zIndex: 2}}>
                <Typography
                    sx={{color: '#fff', fontSize: 20, fontWeight: 500}}>{item.nombre}</Typography>
            </Grid>


        </Grid>

    )

}
export default Tarjeta_Categoria_Cuadrada