/**************************************************
 * Nombre:       Tarjeta_indicador
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import {CountUp} from "use-count-up";
import {useInView} from "react-intersection-observer";

const Tarjeta_indicador = ({item}) => {
    const [ref, inView] = useInView();

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            ref={ref}
        >

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <Typography sx={{color: '#fff', fontSize: 48, fontWeight: 800}}>
                    {"+ "}
                    <CountUp
                        isCounting={inView}
                        end={item.numero}
                        duration={5}
                        thousandsSeparator={"."}
                        start={0}
                        easing={"easeInCubic"}

                    />
                </Typography>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <Typography sx={{color: '#fff', fontSize: 20, fontWeight: 400, textAlign: 'center'}}>
                    {item.nombre}
                </Typography>
            </Grid>


        </Grid>
    )

}
export default Tarjeta_indicador    