/**************************************************
 * Nombre:       BarraCel
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {ButtonBase, Drawer, Grid, IconButton, Typography} from "@mui/material";
import {theme} from "../../Tema";
import logo from '../../Recursos/logo_blanco.svg'
import {Close, Menu} from "@mui/icons-material";
import {useLocation, useNavigate} from "react-router-dom";
import {useState} from "react";
import {SECCIONES} from "../../Constantes";
import {scrolearAComponente} from "../../Utilidades/scrolearAComponente";
import {useCarritoCompras} from "../../Modulo_carrito_compras/Hooks/useCarritoCompras";

const BarraCel = () => {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const location = useLocation()
    const {BotonCarrito} = useCarritoCompras()

    const navegar = (item) => {


        setOpen(false)
        let camino = location.pathname
        let seccion = camino.split('/')[1]
        if (seccion === 'Portafolio' || seccion === 'Detalle') {
            navigate('/Home')
            setTimeout(() => {
                try {
                    scrolearAComponente(item)
                } catch (e) {

                }

            }, 600)
        } else {
            if (item === 'Portafolio') {
                navigate('Portafolio')
            } else {

                scrolearAComponente(item)

            }
        }


    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{backgroundColor: theme.palette.primary.main, px: 2, py: 2}}
        >

            <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                <img
                    onClick={() => navigate('/')}
                    src={logo} width={120} height={'auto'} style={{cursor: 'pointer'}}/>
            </Grid>

            <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-end'}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                >

                    <Grid item container lg={4} sm={2} xs={4} sx={{justifyContent: 'flex-end', marginTop: 0.5}}>
                        <BotonCarrito/>
                    </Grid>

                    <Grid item container lg={4} sm={2} xs={4} sx={{justifyContent: 'flex-end'}}>
                        <IconButton onClick={() => setOpen(true)}>
                            <Menu sx={{fill: '#fff', width: 36, height: 36}}/>
                        </IconButton>
                    </Grid>


                </Grid>


            </Grid>


            <Drawer anchor={'right'} open={open} onClose={() => setOpen(false)}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{width: 250, p: 2}}
                >

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                        <IconButton
                            onClick={() => setOpen(false)}
                        >
                            <Close sx={{fill: '#fff'}}/>
                        </IconButton>
                    </Grid>


                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                        >

                            {SECCIONES.map((item, index) => {
                                return (
                                    <Grid key={`sd${index}`} item container lg={12} sm={12} xs={12}
                                          sx={{justifyContent: 'center', marginTop: 2}}>
                                        <ButtonBase
                                            onClick={() => navegar(item)}
                                        >
                                            <Typography
                                                sx={{color: '#fff', fontSize: 24, fontWeight: 500}}>{item}</Typography>
                                        </ButtonBase>
                                    </Grid>
                                )
                            })}

                        </Grid>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12}
                          sx={{justifyContent: 'center', position: 'fixed', bottom: '6%', left: '20%'}}>
                        <img src={logo} width={150} height={'auto'}/>
                    </Grid>

                </Grid>
            </Drawer>

        </Grid>
    )

}
export default BarraCel    