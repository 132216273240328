/**************************************************
 * Nombre:       Catalogo
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, useMediaQuery} from "@mui/material";
import Categorias_Catalogo from "../Componentes/Categorias_Catalogo";
import Categoria_Detalle from "../Componentes/Categoria_Detalle";
import Lista_Productos from "../Componentes/Lista_Productos";
import {useEffect, useState} from "react";
import {irArriba} from "../../Utilidades/irArriba";
import {theme} from "../../Tema";
import {obtenerCol} from "../../Servicios/BD/obtenerCol";
import img_tem from '../../Recursos/fondo_categoria.png'
import {scrolearAComponente} from "../../Utilidades/scrolearAComponente";
import {useParams} from "react-router-dom";
import useRecuperarColleccionListeningProg from "../../Servicios/BD/useRecuperarColleccionListeningProg";
import {where} from "firebase/firestore";

const Catalogo = () => {
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [categorias, setCategorias] = useState([{
        img: img_tem
    }, {img: img_tem}, {img: img_tem}, {img: img_tem}, {img: img_tem}])
    const [categoriasEscogida, setCategoriaEscogida] = useState('')
    const parms = useParams()
    const [wheres, setWheres] = useState([])
    const {data, cargarMas} = useRecuperarColleccionListeningProg({
        col: 'productos',
        orden: 'descuento',
        wheres: wheres,
        numero: 15,
    })


    const escoger = (e) => {
        setCategoriaEscogida(e)
        setWheres([where('categoria', '==', e.nombre)])
        scrolearAComponente('detallecat')
    }

    useEffect(() => {

        irArriba()

        let cat = ''

        if (parms && parms.id) {
            cat = parms.id
        }

        obtenerCol('categorias').then((dox) => {
            if (dox.res) {
                setCategorias(dox.data)
                let index = -1
                if (cat) {
                    index = dox.data.findIndex((e) => e.nombre === cat)
                }

                if (index < 0) {
                    setCategoriaEscogida(dox.data[0])
                } else {
                    escoger(dox.data[index])
                }


            }
        })


    }, [parms]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <Categorias_Catalogo categorias={categorias} click={escoger}/>
            </Grid>

            <Grid id={'detallecat'} item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-end', marginTop: -54}}>

            </Grid>

            <Grid id={'detallecat'} item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start', marginTop: masSM ? -18 : -24}}>
                <Categoria_Detalle categoria={categoriasEscogida}/>
            </Grid>

            <Grid id={'lista'} item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: sCell ? 4 : 8}}>
                <Lista_Productos data={data}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                <Button
                    onClick={() => cargarMas()}
                    sx={{px: 6}}>Cargar Mas</Button>
            </Grid>


        </Grid>
    )

}
export default Catalogo    