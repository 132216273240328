/**************************************************
 * Nombre:       Home
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, useMediaQuery} from "@mui/material";
import Producto_Home from "../Componentes/Producto_Home";
import Anuncios from "../Componentes/Anuncios";
import Categorias from "../Componentes/Categorias";
import PorQueComprar from "../Componentes/PorQueComprar";
import Contactos from "../Componentes/Contactos";
import ExperienciaCultura from "../Componentes/ExperienciaCultura";
import Testimonios from "../Componentes/Testimonios";
import {theme} from "../../Tema";
import {useEffect} from "react";
import {irArriba} from "../../Utilidades/irArriba";
import {useReciboPago} from "../../Modulo_Pasarela_Pagos/Hooks/useReciboPago";
import logo from '../../Recursos/logo_color.png'

const Home = () => {
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const {Dialogo} = useReciboPago({logo: logo})

    useEffect(() => {
        irArriba()
    }, [])
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Dialogo/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <Producto_Home/>
            </Grid>

            <Grid id={'Anuncios'} item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'center', marginTop: -40}}/>


            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start', marginTop: sCell ? -24 : -10}}>
                <Anuncios/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: masSM ? 12 : 6}}>
                <Categorias/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start', marginTop: masSM ? 22 : 14}}>
                <PorQueComprar/>
            </Grid>

            <Grid id={'Contactos'} item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'center', marginTop: 0}}/>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: masSM ? 20 : 2}}>
                <Contactos/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start', marginTop: masSM ? 22 : 12}}>
                <ExperienciaCultura/>
            </Grid>

            <Grid id={'Testimonios'} item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'center', marginTop: 0}}/>

            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start', marginTop: masSM ? 18 : 10}}>
                <Testimonios/>
            </Grid>

        </Grid>
    )

}
export default Home    