// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getAuth} from 'firebase/auth'
import {getFirestore} from 'firebase/firestore'
import {getStorage} from 'firebase/storage'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// narinosouvenirs.colombia@gmail.com
const firebaseConfig = {
    apiKey: "AIzaSyDEGvGx4yDDtQ9DlG7NNWR3hIPY__o52ws",
    authDomain: "narino-souvenirs.firebaseapp.com",
    projectId: "narino-souvenirs",
    storageBucket: "narino-souvenirs.appspot.com",
    messagingSenderId: "479744281001",
    appId: "1:479744281001:web:c6f8ba8572413c8615dcb8",
    measurementId: "G-BXPWVS2QEW"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

export const auth = getAuth(app)
export const fire = getFirestore(app)
export const storage = getStorage(app)