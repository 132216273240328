/**************************************************
 * Nombre:       Dashboard
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import DashboardBase from "../Modulo_dashboard/Componentes/DashboardBase";
import MenuLateral from "../Modulo_dashboard/Componentes/MenuLateral";
import {
    DashboardOutlined,
    Flag, Group,
    LocalMallOutlined,
    ShoppingCart,
    Stars,
    SwitchAccount, Title,
    Warning
} from "@mui/icons-material";
import logo from '../Recursos/logo_oscuro.svg'
import logo_redondo from '../Recursos/logo_redondo.svg'
import logo_blanco from '../Recursos/logo_blanco.svg'
import {useContext} from "react";
import {usuarioContext} from "../App";
import Seccion_Catalogo from "./Secciones/Seccion_Catalogo";
import Seccion_Categorias from "./Secciones/Seccion_Categorias";
import {funSalir} from "../Servicios/Auth/funSalir";
import Seccion_Potenciales from "./Secciones/Seccion_Potenciales";
import Seccion_Pqrs from "./Secciones/Seccion_Pqrs";
import Seccion_Pedidos from "./Secciones/Seccion_Pedidos";
import Seccion_Anuncio from "./Secciones/Seccion_Anuncio";
import Seccion_Artesanos from "./Secciones/Seccion_Artesanos";
import Seccion_Textos from "./Secciones/Seccion_Textos";

const Dashboard = () => {
    const {usuario} = useContext(usuarioContext)

    const clickPerfil = () => {

    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <DashboardBase secciones={secciones}>
                <MenuLateral
                    logo={logo}
                    clickPerfil={clickPerfil}
                    logoRedondo={logo_redondo}
                    logoBlanco={logo_blanco}
                    usuario={usuario}
                    salir={funSalir}
                />
            </DashboardBase>


        </Grid>
    )

}
export default Dashboard

const secciones = [

    {
        nombre: 'Pedidos',
        Componente: <Seccion_Pedidos/>,
        icono: ShoppingCart,
        categoria: 'propia',
        camino: '/',
    },
    {
        nombre: 'Catalogo',
        Componente: <Seccion_Catalogo/>,
        icono: LocalMallOutlined,
        categoria: 'Tienda',
        camino: '/Catalogo',
    },
    {
        nombre: 'Categorias',
        Componente: <Seccion_Categorias/>,
        icono: Flag,
        categoria: 'Tienda',
        camino: '/Categorias',
    },
    {
        nombre: 'Potenciales',
        Componente: <Seccion_Potenciales/>,
        icono: SwitchAccount,
        categoria: 'Formularios',
        camino: '/Potenciales',
    },
    {
        nombre: 'Pqrs',
        Componente: <Seccion_Pqrs/>,
        icono: Warning,
        categoria: 'Formularios',
        camino: '/Pqrs',
    },
    {
        nombre: 'Anuncio',
        Componente: <Seccion_Anuncio/>,
        icono: Stars,
        categoria: 'Contenido',
        camino: '/Anuncio',
    },
    {
        nombre: 'Artesanos',
        Componente: <Seccion_Artesanos/>,
        icono: Group,
        categoria: 'Contenido',
        camino: '/Artesanos',
    },
    {
        nombre: 'Textos',
        Componente: <Seccion_Textos/>,
        icono: Title,
        categoria: 'Contenido',
        camino: '/Textos',
    }


]