/**************************************************
 * Nombre:       Pagina
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, useMediaQuery} from "@mui/material";
import Home from "./Secciones/Home";
import Barra from "./Barra/Barra";
import Footer from "./Footer";
import Catalogo from "./Secciones/Catalogo";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Detalle_Producto from "./Secciones/Detalle_Producto";
import {theme} from "../Tema";
import {funEnviarNotificacionTopic} from "../M_Notificaciones/Funciones/funEnviarNotificacionTopic";

const Pagina = () => {
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Router>

                <Grid item container lg={12} sm={12} xs={12} sx={{
                    justifyContent: 'flex-start', position: sCell ? 'relative' : 'sticky', top: '0%', zIndex: 4
                }}>
                    <Barra/>
                </Grid>

                <Routes>

                    <Route path={"/"} element={<Home/>}/>
                    <Route path={"/Home"} element={<Home/>}/>
                    <Route path={"/*"} element={<Home/>}/>
                    <Route path={"/Portafolio"} element={<Catalogo/>}/>
                    <Route path={"/Portafolio/:id"} element={<Catalogo/>}/>
                    <Route path={"/Detalle"} element={<Detalle_Producto/>}/>
                    <Route path={"/Detalle/:id"} element={<Detalle_Producto/>}/>

                </Routes>

                <Grid item container lg={12} sm={12} xs={12}
                      sx={{justifyContent: 'flex-start', marginTop: masSM ? 24 : 10}}>
                    <Footer/>
                </Grid>

            </Router>

        </Grid>)

}
export default Pagina    