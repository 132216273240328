/**************************************************
 * Nombre:       Contactos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import {theme} from "../../Tema";
import {GpsFixed} from "@mui/icons-material";
import {irUrl} from "../../Utilidades/irUrl";
import {LINKMAP} from "../../Constantes";

const Contactos = () => {
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))

    return (<Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
    >

        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{maxWidth: '1400px', px: 3}}
        >

            <Grid item container lg={9} sm={11} xs={12} sx={{justifyContent: 'flex-start'}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={4}
                >

                    <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: 'flex-start'}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >

                            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                <Typography
                                    sx={{
                                        color: theme.palette.secondary.main,
                                        fontSize: 36,
                                        fontWeight: 800,
                                        lineHeight: 1.1,
                                        textAlign: sCell ? 'center' : 'left'
                                    }}>
                                    Contactos y ubicación
                                </Typography>
                            </Grid>

                            <Grid item container lg={11} sm={12} xs={12}
                                  sx={{justifyContent: 'flex-start', marginTop: sCell ? 2 : 0}}>
                                <Typography sx={{
                                    color: '#666666',
                                    fontSize: 16,
                                    fontWeight: 400,
                                    textAlign: sCell ? 'center' : 'left'
                                }}>
                                    Ubicados en el corazón de la ciudad de Pasto-Nariño rodeados de cultura y
                                    tradición nariñense.
                                </Typography>
                            </Grid>

                            <Grid item container lg={12} sm={12} xs={12}
                                  sx={{justifyContent: sCell ? 'center' : 'flex-start', marginTop: 4}}>
                                <Typography sx={{
                                    color: '#666666',
                                    fontSize: 16,
                                    fontWeight: 600,
                                    textAlign: sCell ? 'center' : 'left'
                                }}>
                                    Tienda Plaza de Nariño Cra 25# 18-45
                                    <br/>
                                    Tienda Hotel V1501 Calle 20# 33-50
                                    <br/>


                                    <br/>
                                    Cel: 318 327 0023
                                    <br/>
                                    hola@narinosouvenirs.com
                                    <br/>
                                    personalizados-empresas@narinosouvenirs.com
                                    <br/>
                                    proveedores@narinosouvenirs.com


                                    <br/>
                                    <br/>

                                    <Button
                                        onClick={() => irUrl(LINKMAP)}
                                        startIcon={<GpsFixed/>}>
                                        Llévame
                                    </Button>


                                </Typography>
                            </Grid>


                        </Grid>
                    </Grid>

                    <Grid item container lg={6} sm={6} xs={12}
                          sx={{justifyContent: 'flex-start', marginTop: sCell ? 4 : 0}}>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d626.3399436883369!2d-77.27891418421609!3d1.2149217854740653!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e2ed5f586557ec3%3A0x5f2848b5f21e8108!2sNari%C3%B1o%20Souvenirs!5e0!3m2!1ses-419!2sco!4v1708534119624!5m2!1ses-419!2sco"
                            width="100%" height="350" style={{border: 0}} loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </Grid>

                </Grid>
            </Grid>

        </Grid>

    </Grid>)

}
export default Contactos