/**************************************************
 * Nombre:       Detalle_Producto
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import fondo from "../../Recursos/fondo_producto.png";
import fondo_card from "../../Recursos/fondo_anuncios.svg";
import Tarjeta_Producto_Detalle from "../Componentes/Tarjeta_Producto_Detalle";
import {theme} from "../../Tema";
import {
    AddCardOutlined,
    DashboardCustomize,
    LocalShipping,
    LocalShippingOutlined,
    SellOutlined
} from "@mui/icons-material";
import Testimonios from "../Componentes/Testimonios";
import {useEffect, useState} from "react";
import {obtenerCol} from "../../Servicios/BD/obtenerCol";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import {useParams} from "react-router-dom";
import {irArriba} from "../../Utilidades/irArriba";


const Detalle_Producto = () => {
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [producto, setProducto] = useState({})
    const parms = useParams()


    useEffect(() => {
        irArriba()
        if (parms && parms.id) {
            obtenerDoc('productos', parms.id).then((dox) => {
                if (dox.res) {
                    setProducto(dox.data)
                }
            })
        }

    }, [parms])
    return (
        <>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{
                    backgroundImage: `url(${fondo})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}
            >

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    sx={{maxWidth: '1400px', px: 3}}
                >

                    <Grid item container lg={9} sm={12} xs={12}
                          sx={{justifyContent: 'center', marginBottom: sCell ? -12 : -6}}>
                        <Tarjeta_Producto_Detalle item={producto}/>
                    </Grid>

                    <Grid item container lg={10} sm={12} xs={12}
                          sx={{justifyContent: 'center', marginBottom: sCell ? -14 : -9}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                            sx={{
                                backgroundColor: theme.palette.primary.main,
                                backgroundImage: `url(${fondo_card})`,
                                p: 2,
                                pb: 3,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'center',
                                backgroundPosition: 'center'
                            }}
                            spacing={sCell ? 0 : 2}

                        >


                            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end'}}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    sx={{px: sCell ? 1 : 2}}
                                    spacing={2}
                                >


                                    {items.map((item, index) => {
                                        return (
                                            <Grid item container lg={3} sm={12} xs={6}
                                                  sx={{justifyContent: 'flex-start'}}>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                    sx={{
                                                        borderRight: index > 2 ? 0 : 1,
                                                        pl: sCell ? 0 : 2,
                                                        borderColor: sCell ? '#00000000' : '#fff'
                                                    }}
                                                >

                                                    <Grid item container lg={12} sm={12} xs={12}
                                                          sx={{justifyContent: 'flex-start'}}>
                                                        <item.img sx={{
                                                            fill: '#fff',
                                                            height: sCell ? 40 : 50,
                                                            width: sCell ? 40 : 50
                                                        }}/>
                                                    </Grid>

                                                    <Grid item container lg={12} sm={12} xs={12}
                                                          sx={{justifyContent: 'flex-start'}}>
                                                        <Typography sx={{
                                                            color: '#fff',
                                                            fontWeight: 500,
                                                            fontSize: sCell ? 16 : 24
                                                        }}>
                                                            {item.nombre}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item container lg={12} sm={12} xs={12}
                                                          sx={{
                                                              justifyContent: 'flex-start',
                                                              marginTop: sCell ? 0 : -1
                                                          }}>
                                                        <Typography sx={{
                                                            color: '#fff',
                                                            fontWeight: 300,
                                                            fontSize: sCell ? 12 : 16
                                                        }}>
                                                            {item.descripcion}
                                                        </Typography>
                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                        )
                                    })}


                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>

                </Grid>

            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 24}}>
                <Testimonios/>
            </Grid>

        </>
    )

}
export default Detalle_Producto

const items = [
    {
        nombre: 'Envíos nacionales',
        descripcion: '14 empresas de envio',
        img: LocalShippingOutlined
    },
    {
        nombre: 'Pagos seguros',
        descripcion: 'Multiples medios de pago',
        img: AddCardOutlined
    },
    {
        nombre: 'Ofertas exclusivas',
        descripcion: 'Productos desde $ 25.000',
        img: SellOutlined
    },
    {
        nombre: 'Múltiples categorías',
        descripcion: 'Todo e 1 lugar',
        img: DashboardCustomize
    },
]