/**************************************************
 * Nombre:       BarraPc
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, ButtonBase, Grid, IconButton, Typography} from "@mui/material";
import {theme} from "../../Tema";
import logo_blanco from '../../Recursos/logo_blanco.svg'
import {SECCIONES} from "../../Constantes";
import {ShoppingCart} from "@mui/icons-material";
import {useEffect, useState} from "react";
import {scrolearAComponente} from "../../Utilidades/scrolearAComponente";
import {useLocation, useNavigate} from "react-router-dom";
import {useCarritoCompras} from "../../Modulo_carrito_compras/Hooks/useCarritoCompras";

const BarraPc = () => {
    const navigate = useNavigate()
    const [navegando, setNavegando] = useState(false)
    const location = useLocation()
    const {BotonCarrito} = useCarritoCompras()


    const navegar = (item) => {


        let camino = location.pathname
        let seccion = camino.split('/')[1]
        if (seccion === 'Portafolio' || seccion === 'Detalle') {
            navigate('/Home')
            setTimeout(() => {
                try {
                    scrolearAComponente(item)
                } catch (e) {

                }
            }, 600)
        } else {
            if (item === 'Portafolio') {
                navigate('/Portafolio')
            } else {
                try {
                    scrolearAComponente(item)
                }catch (w){

                }

            }
        }


    }

    const funcion = (e) => {
        if (window.scrollY > 100 && !navegando) {
            setNavegando(true)
        } else if (window.scrollY < 100) {
            setNavegando(false)
        }


    }

    useEffect(() => {
        window.addEventListener('scroll', (e) => funcion(e));
    }, []);
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{
                transition: 'all .2s ease-in-out',
                backgroundColor: theme.palette.primary.main,
                borderBottom: 8,
                borderColor: theme.palette.secondary.main,
                boxShadow: navegando ? 4 : 0
            }}

        >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{transition: 'all .2s ease-in-out', maxWidth: '1400px', px: 4, py: navegando ? 1 : 2}}
            >

                <Grid item container lg={6} sm={5} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <img
                        onClick={() => navigate('/')}
                        src={logo_blanco} width={navegando ? 100 : 140} height={'auto'}
                        style={{cursor: 'pointer', transition: 'all .2s ease-in-out'}}/>
                </Grid>

                <Grid item container lg={4} sm={5} xs={12} sx={{justifyContent: 'flex-end'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                    >


                        {SECCIONES.map((item, index) => {
                            return (
                                <ButtonBase
                                    // onClick={() => }
                                    onClick={() => navegar(item)}
                                    key={`aa${index}`}
                                    sx={{
                                        transition: 'all .2s ease-in-out',
                                        borderBottom: 2, borderColor: '#ffffff00',
                                        '&:hover': {borderColor: '#ffffff'}
                                    }}>
                                    <Typography sx={{
                                        color: '#fff',
                                        fontSize: 18,
                                        fontWeight: 500,

                                    }}>{item}</Typography>
                                </ButtonBase>
                            )
                        })}


                    </Grid>
                </Grid>

                <Grid item container lg={2} sm={2} xs={12} sx={{justifyContent: 'flex-end'}}>
                    <BotonCarrito/>


                </Grid>

            </Grid>


        </Grid>
    )

}
export default BarraPc    