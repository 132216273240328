/**************************************************
 * Nombre:       Categorias
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import {theme} from "../../Tema";
import {useEffect, useState} from "react";
import Tarjeta_Categoria from "./Tarjeta_Categoria";
import {ArrowRight, ArrowRightAlt} from "@mui/icons-material";
import {Splide, SplideSlide} from "@splidejs/react-splide";
import {obtenerCol} from "../../Servicios/BD/obtenerCol";
import {useNavigate} from "react-router-dom";

const Categorias = () => {
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [categorias, setCategorias] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        obtenerCol('categorias').then((dox) => {
            if (dox.res) {
                setCategorias(dox.data)
            }
        })
    }, []);
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >


                <Grid item container lg={10} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >

                        <Grid item container lg={6} sm={6} xs={4} sx={{justifyContent: 'flex-start'}}>
                            <Typography
                                sx={{color: theme.palette.secondary.main, fontSize: sCell ? 26 : 38, fontWeight: 900}}
                            >Categorías</Typography>
                        </Grid>

                        <Grid item container lg={6} sm={6} xs={8}
                              sx={{justifyContent: 'flex-end', marginBottom: sCell ? 0 : -2}}>


                            <Button
                                onClick={() => navigate('/Portafolio')}
                                variant={'text'} color={'primary'} endIcon={<ArrowRightAlt/>}
                                sx={{color: theme.palette.primary.main, px: 1, py: 0.5}}>
                                Ver Catálogo
                            </Button>
                        </Grid>

                    </Grid>

                </Grid>

                <Grid item container lg={10} sm={11} xs={12}
                      sx={{justifyContent: 'flex-start', marginTop: masSM ? 8 : 3}}>
                    <Splide aria-label="My Favorite Images" style={{width: '100%'}}
                            options={{
                                autoplay: true,
                                speed: 3000,
                                rewind: true,
                                perPage: sCell ? 3 : 5,
                                arrows: sCell,
                                gap: sCell ? 20 : 40,
                                pagination: false,
                                width: '100%',
                                drag: 'free',

                            }}
                    >
                        {categorias.map((item, index) => {
                            return (
                                <SplideSlide key={`wr${index}`} >
                                    <Tarjeta_Categoria item={item}/>
                                </SplideSlide>
                            )
                        })}


                    </Splide>
                </Grid>

            </Grid>


        </Grid>
    )

}
export default Categorias