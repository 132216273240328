/**************************************************
 * Nombre:       Dialogo_Producto
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid} from "@mui/material";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import Formulario_Productos from "../Formularios/Formulario_Productos";
import {LoadingButton} from "@mui/lab";
import {Delete, Flag, LocalMall} from "@mui/icons-material";
import {subirImagenFile} from "../../Servicios/Storage/subirImagenFile";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {useEffect, useState} from "react";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_redondo.svg'
import {borrarDoc} from "../../Servicios/BD/borrarDoc";
import Formulario_Categoria from "../Formularios/Formulario_Categoria";
import Formulario_Anuncio from "../Formularios/Formulario_Anuncio";

const Dialogo_Anuncio = ({cerrar, realizado, datos}) => {
    const {props, obtenerEntidad} = useFormulario({valoresDefecto: datos})
    const [cargando, setCargando] = useState(false)
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({
        logo: logo,
    })


    const guardar = () => {

        obtenerEntidad().then((entidad) => {
            abrirCargador('Subiendo Datos')
            let obj = entidad
            obj.fecha = new Date().getTime()
            guardarNuevo(obj)
        })
    }

    const guardarNuevo = (obj) => {

        subirImagenFile(obj.img, 'anuncio').then((dox) => {
            if (dox.res) {
                let objImg = obj
                objImg.img = dox.data
                objImg.id = 'anuncio'

                guardarDoc('anuncio', objImg).then((dox) => {
                    if (dox.res) {
                        realizado()
                        cerrar()
                    } else {
                        alert('Ups algo inesperado paso al subir el producto, por favor intentalo mas tarde')
                    }
                    cerrarCargador()
                })


            } else {
                cerrarCargador()
                alert('No se pudo subir imagen por favor intentalo mas tarde')
            }
        })
    }


    return (

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Cargador/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <Formulario_Anuncio props={props}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', mt: 3, mb: 1}}>
                <LoadingButton
                    loading={cargando}
                    onClick={() => guardar()}
                    startIcon={<Flag/>}
                    color={'primary'}
                    variant={'contained'}
                >
                    {datos && datos.id ? 'Actualizar Anuncio' : 'Crear Anuncio'}
                </LoadingButton>
            </Grid>


        </Grid>

    )

}
export default Dialogo_Anuncio