/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import IngresoImagen from "../../Modulo_formularios/Componentes/IngresoImagen";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {
    ArrowRight,
    ArrowRightAlt,
    AttachMoney, Flag,
    Height, LinkSharp,
    LocalMall,
    LocalMallOutlined,
    LocalOffer, Scale,
    Upgrade
} from "@mui/icons-material";
import IngresoDinero from "../../Modulo_formularios/Componentes/IngresoDinero";
import IngresoCheckGrupo from "../../Modulo_formularios/Componentes/IngresoCheckGrupo";
import IngresoSelect from "../../Modulo_formularios/Componentes/IngresoSelect";
import {CATEGORIAS} from "../../Constantes";
import IngresoImagenCrop from "../../Modulo_formularios/Componentes/IngresoImagenCrop";

const Formulario_Anuncio = ({...props}) => {

    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{p: 1}}
            >


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoImagenCrop ancho={3} alto={2} dato={'img'} {...props}/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: -3}}>
                    <Typography>Imagen 3x2</Typography>
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Flag} nombre={'Nombre'} dato={'nombre'}  {...props}
                                  requerido={'El nombre de la categoria es requerido*'}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto nombre={'Descripcion'} dato={'descripcion'}  {...props}
                                  requerido={'La descripcion de la categoria es requerida*'} lineas={4}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={LinkSharp} nombre={'Link de mas informacion'} dato={'link'}  {...props}

                    />
                </Grid>


            </Grid>
        </form>

    )

}
export default Formulario_Anuncio