/**************************************************
 * Nombre:       Tarjeta_Artesanos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, IconButton, Typography, useMediaQuery} from "@mui/material";
import cinta from "../../Recursos/cinta.png";
import tiktok from "../../Recursos/ic_tiktok.svg";
import {theme} from "../../Tema";
import {FacebookOutlined, Instagram} from "@mui/icons-material";
import {irUrl} from "../../Utilidades/irUrl";

const Tarjeta_Artesanos = ({item}) => {
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const sTab = useMediaQuery(theme.breakpoints.only('sm'))

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{p: sCell ? 0 : 10}}
        >
            <Grid item container lg={5} sm={5} xs={12} sx={{justifyContent: 'flex-start'}}>


                <img src={cinta} width={sCell ? '100%' : '140%'} height={'auto'} style={{
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundImage: `url(${item.img})`,
                    margin: sCell ? 0 : -100,
                    borderTopLeftRadius: sCell ? 0 : 20,
                    borderBottomLeftRadius: sCell ? 0 : 20
                }}/>

            </Grid>


            <Grid item container lg={6} sm={7} xs={12}
                  sx={{justifyContent: 'flex-start', marginTop: sCell ? 0 : -1, px: sCell ? 6 : 0}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{pl: sCell ? 0 : sTab ? 6 : 14, py: 4}}
                >

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                        <Typography sx={{
                            fontSize: masSM ? 38 : 32,
                            fontWeight: 800,
                            color: '#fff',
                            lineHeight: 1.1,
                            textAlign: sCell ? 'center' : 'left'
                        }}>
                            {item.titulo}
                        </Typography>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12}
                          sx={{justifyContent: 'flex-start', marginTop: 2, zIndex: 2}}>
                        <Typography
                            sx={{
                                fontSize: 16,
                                fontWeight: 400,
                                fontStyle: 'italic',
                                color: '#fff',
                                display: '-webkit-box',
                                overflow: 'hidden',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 3,
                                textOverflow: "ellipsis",
                            }}>
                            {item.descripcion}
                        </Typography>
                    </Grid>


                    <Grid item container lg={8} sm={12} xs={12}
                          sx={{justifyContent: 'flex-start', marginTop: 4}}>
                        <Typography
                            sx={{fontSize: 20, fontWeight: 600, color: '#fff'}}>
                            {item.nombre}
                        </Typography>
                    </Grid>

                    <Grid item container lg={4} sm={12} xs={12}
                          sx={{justifyContent: 'flex-start', marginTop: masSM ? 4 : 0, zIndex: 2}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent={sCell ? 'flex-star' : "center"}
                            alignItems="flex-start"
                        >

                            {item && item.instagram &&
                                <Grid key={item.nombre} item container lg={4} sm={2} xs={2}
                                      sx={{justifyContent: 'center'}}>
                                    <IconButton onClick={() => irUrl(item.instagram)}>
                                        <Instagram sx={{fill: '#fff', width: 36, height: 36}}/>
                                    </IconButton>
                                </Grid>
                            }

                            {item && item.facebook &&
                                <Grid key={item.nombre} item container lg={4} sm={2} xs={2}
                                      sx={{justifyContent: 'center'}}>
                                    <IconButton onClick={() => irUrl(item.facebook)}>
                                        <FacebookOutlined sx={{fill: '#fff', width: 36, height: 36}}/>
                                    </IconButton>
                                </Grid>
                            }

                            {item && item.tiktok &&
                                <Grid key={item.nombre} item container lg={4} sm={2} xs={2}
                                      sx={{justifyContent: 'center', marginTop: 0.5}}>
                                    <IconButton onClick={() => irUrl(item.tiktok)} sx={{p: 1}}>
                                        <img src={tiktok} style={{width: 30, height: 30}}/>

                                    </IconButton>
                                </Grid>
                            }


                        </Grid>
                    </Grid>


                </Grid>
            </Grid>


        </Grid>
    )

}
export default Tarjeta_Artesanos

const redes = [
    {
        nombre: 'facebook',
        icono: FacebookOutlined
    },
    {
        nombre: 'instagram',
        icono: Instagram
    }
]