/**************************************************
 * Nombre:       Dialogo_Carrito
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, IconButton, Step, StepLabel, Stepper, Typography} from "@mui/material";
import {ArrowLeft, Close, KeyboardBackspace, Task, TaskAlt} from "@mui/icons-material";
import Tarjeta_Producto from "../Tarjetas/Tarjeta_Producto";
import {useFuncionesCarrito} from "../Hooks/useFuncionesCarrito";
import ListaProductos from "../Pasos/ListaProductos";
import {useMemo, useState} from "react";
import IngresoDatos from "../Pasos/IngresoDatos";
import OpcionesEnvio from "../Pasos/OpcionesEnvio";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import ValoresTotales from "../Pasos/ValoresTotales";

const Dialogo_Carrito = ({cerrar}) => {
    const {productos} = useFuncionesCarrito()
    const [paso, setPaso] = useState(0)
    const {props, obtenerEntidad} = useFormulario({valoresDefecto: {}})
    const [idPedido, setIdPedido] = useState('')
    const [cargando, setCargando] = useState(false)


    const siguiente = () => {

        if (paso == 1) {
            obtenerEntidad().then((entidad) => {
                setPaso(paso + 1)
            })

        } else {
            setPaso(paso + 1)
        }


    }

    const atras = () => {
        setPaso(paso - 1)
    }


    const getComponente = (paso) => {

        switch (paso) {
            case  0 :
                return <ListaProductos productos={productos}/>
                break
            case 1 :
                return <IngresoDatos props={props}/>
                break
            case 2 :
                return <OpcionesEnvio obtenerEntidad={obtenerEntidad} props={props} cargando={cargando}
                                      setCargando={setCargando}/>
                break
            case 3 :
                return <ValoresTotales obtenerEntidad={obtenerEntidad} idPedido={idPedido} setIdPedido={setIdPedido}/>
                break

        }

    }


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{p: 2}}
        >

            <Grid item container lg={8} sm={8} xs={8} sx={{justifyContent: 'flex-start'}}>
                <Typography sx={{fontWeight: 600, color: '#00000080', fontSize: 16}}>Carrito de
                    compras</Typography>
            </Grid>

            <Grid item container lg={4} sm={4} xs={4} sx={{justifyContent: 'flex-end', marginTop: 0}}>
                <IconButton
                    onClick={() => cerrar()}
                >
                    <Close/>
                </IconButton>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 1}}>

                <Stepper activeStep={paso} sx={{width: '100%'}} alternativeLabel>
                    <Step>
                        <StepLabel>{''}</StepLabel>
                    </Step>

                    <Step>
                        <StepLabel>{''}</StepLabel>
                    </Step>

                    <Step>
                        <StepLabel>{''}</StepLabel>
                    </Step>

                </Stepper>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    sx={{marginTop: 3}}
                >

                    {getComponente(paso)}

                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    sx={{px: 0, marginTop: 8}}
                >

                    <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                        {paso > 0 &&
                            <Button
                                onClick={() => atras()}
                                variant={'text'}
                                startIcon={<KeyboardBackspace/>}
                                sx={{width: 140, color: '#00000080'}}>Atras</Button>
                        }
                    </Grid>

                    <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-end'}}>
                        {paso !== 3 && !cargando &&
                            <Button
                                color={'primary'}
                                onClick={() => siguiente()}
                                startIcon={<TaskAlt/>}
                            >Continuar</Button>
                        }
                    </Grid>


                </Grid>

            </Grid>


        </Grid>

    )

}
export default Dialogo_Carrito