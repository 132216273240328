/**************************************************
 * Nombre:       Dialogo_Producto
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid} from "@mui/material";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import Formulario_Productos from "../Formularios/Formulario_Productos";
import {LoadingButton} from "@mui/lab";
import {Delete, Flag, LocalMall} from "@mui/icons-material";
import {subirImagenFile} from "../../Servicios/Storage/subirImagenFile";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {useEffect, useState} from "react";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_redondo.svg'
import {borrarDoc} from "../../Servicios/BD/borrarDoc";
import Formulario_Categoria from "../Formularios/Formulario_Categoria";

const Dialogo_Categoria = ({cerrar, realizado, datos}) => {
    const {props, obtenerEntidad} = useFormulario({valoresDefecto: datos})
    const [cargando, setCargando] = useState(false)
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({
        logo: logo,
    })


    const guardar = () => {

        obtenerEntidad().then((entidad) => {
            abrirCargador('Subiendo Datos')
            let obj = entidad
            obj.fecha = new Date().getTime()
            guardarNuevo(obj)
        })
    }

    const guardarNuevo = (obj) => {

        subirImagenFile(obj.img, 'categorias').then((dox) => {
            if (dox.res) {
                let objImg = obj
                objImg.img = dox.data

                subirImagenFile(obj.img_larga, 'categorias').then((dex) => {

                    if (dex.res) {
                        objImg.img_larga = dex.data

                        guardarDoc('categorias', objImg).then((dox) => {
                            if (dox.res) {
                                realizado()
                                cerrar()
                            } else {
                                alert('Ups algo inesperado paso al subir el producto, por favor intentalo mas tarde')
                            }
                            cerrarCargador()
                        })
                    } else {
                        cerrarCargador()
                        alert('No se pudo subir imagen por favor intentalo mas tarde')
                    }

                })

            } else {
                cerrarCargador()
                alert('No se pudo subir imagen por favor intentalo mas tarde')
            }
        })
    }


    const borrar = () => {
        abrirCargador('Borrando Categoria')
        borrarDoc('categorias', datos.id).then((dox) => {
            cerrarCargador()
            realizado()
            cerrar()
        })
    }

    return (

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Cargador/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <Formulario_Categoria props={props}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', mt: 3, mb: 1}}>
                <LoadingButton
                    loading={cargando}
                    onClick={() => guardar()}
                    startIcon={<Flag/>}
                    color={'primary'}
                    variant={'contained'}
                >
                    {datos && datos.id ? 'Actualizar Categoria' : 'Crear Categoria'}
                </LoadingButton>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                {datos && datos.id &&
                    <Button
                        onClick={() => borrar()}
                        variant={'text'}
                        sx={{color: '#00000080', px: 0, marginTop: 2}}
                        startIcon={<Delete/>}
                    >Borrar Categoria</Button>
                }
            </Grid>


        </Grid>

    )

}
export default Dialogo_Categoria