/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import IngresoImagen from "../../Modulo_formularios/Componentes/IngresoImagen";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {
    ArrowRight,
    ArrowRightAlt,
    AttachMoney, FaceRetouchingNatural, Flag, Hail,
    Height, ImageAspectRatio,
    LocalMall,
    LocalMallOutlined,
    LocalOffer, Scale, Spa, Star, Start,
    Upgrade
} from "@mui/icons-material";
import IngresoDinero from "../../Modulo_formularios/Componentes/IngresoDinero";
import IngresoCheckGrupo from "../../Modulo_formularios/Componentes/IngresoCheckGrupo";
import IngresoSelect from "../../Modulo_formularios/Componentes/IngresoSelect";
import {CATEGORIAS} from "../../Constantes";
import {useEffect, useState} from "react";
import {obtenerCol} from "../../Servicios/BD/obtenerCol";
import IngresoImagenCrop from "../../Modulo_formularios/Componentes/IngresoImagenCrop";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_redondo.svg'
import {LoadingButton} from "@mui/lab";

const Formulario_Productos = ({...props}) => {
    const [categorias, setCategorias] = useState([])
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({logo: logo})
    const [cargando, setCargando] = useState(false)


    const colocarHome = () => {

        let entidad = props.props.getValues()
        let objEntidad = entidad
        objEntidad.idProducto = entidad.id
        objEntidad.id = 'productoHome'


        setCargando(true)
        guardarDoc('productoHome', entidad).then((dox) => {
            if (dox.res) {
                alert('Producto colocado en Home de manera exitosa')
            } else {
                alert("Ups, algo indesperado paso, por favor intentalo nuevamente")
            }
            setCargando(false)
        })


    }


    useEffect(() => {
        obtenerCol('categorias').then((dox) => {
            if (dox.res) {
                let catsObjeto = dox.data
                let arr = catsObjeto.map((it) => it.nombre)
                setCategorias(arr)
            }
        })
    }, [])
    return (
        <form noValidate style={{width: '100%'}}>

            <Cargador/>

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{p: 1}}
            >
                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <LoadingButton
                        loading={cargando}
                        onClick={() => colocarHome()}
                        variant={'text'} color={'success'} startIcon={<Star/>}
                        sx={{color: '#00000080', px: 1, py: 0.5}}
                    >Colocar en Home</LoadingButton>
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'center'}}>
                    <IngresoImagenCrop dato={'img'} {...props}/>
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'center'}}>
                    <IngresoImagenCrop dato={'img_3x2'} ancho={2} alto={3} {...props}/>
                </Grid>


                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: -3}}>
                    <Typography>Imagen 1x1</Typography>
                </Grid>

                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: -3}}>
                    <Typography>Imagen 3x2</Typography>
                </Grid>


                <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoSelect Icono={ImageAspectRatio} nombre={'Formato Imagen'} dato={'formato'}
                                   opciones={['1:1', '3x2']} {...props}
                                   requerido={'La categoria del producto es requerido*'}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoSelect Icono={Flag} nombre={'Categoria'} dato={'categoria'}
                                   opciones={categorias} {...props}
                                   requerido={'La categoria del producto es requerido*'}
                    />
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={LocalMall} nombre={'Nombre'} dato={'nombre'}  {...props}
                                  requerido={'El nombre del producto es requerido*'}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto nombre={'Descripcion'} dato={'descripcion'}  {...props}
                                  requerido={'La descripcion del producto es requerido*'} lineas={4}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Hail} nombre={'Usuario de artesano'} dato={'artesano'}  {...props}

                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'center'}}>
                    <IngresoDinero Icono={AttachMoney} nombre={'Precio Normal'} dato={'valor'}  {...props}
                                   requerido={'El precio normal del producto es requerido*'}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'center',}}>
                    <IngresoDinero Icono={LocalOffer} nombre={'Precio Rebajado'} dato={'rebajado'}  {...props}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Typography sx={{color: '#00000090'}}>Datos de Envio</Typography>
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Height} nombre={'Alto (cm)'} dato={'alto'} type={'number'}  {...props}
                                  requerido={'El alto del producto es requerido*'}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={ArrowRightAlt} nombre={'Ancho (cm)'} dato={'ancho'} type={'number'}  {...props}
                                  requerido={'El Ancho del producto es requerido*'}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Upgrade} nombre={'Fondo (cm)'} dato={'fondo'} type={'number'}  {...props}
                                  requerido={'El Fondo del producto es requerido*'}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Scale} nombre={'Peso (gr)'} dato={'peso'} type={'number'}  {...props}
                                  requerido={'El Peso del producto es requerido*'}
                    />
                </Grid>

            </Grid>
        </form>

    )

}
export default Formulario_Productos