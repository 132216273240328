/**************************************************
 * Nombre:       Footer
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {ButtonBase, Grid, IconButton, Typography, useMediaQuery} from "@mui/material";
import fondo from '../Recursos/fondo_footer.png'
import fondo_cel from '../Recursos/fondo_footer_cel.png'
import logo from '../Recursos/logo_blanco.svg'
import {REDES, SECCIONES} from "../Constantes";
import {useNavigate} from "react-router-dom";
import {theme} from "../Tema";
import {useDialogo} from "../Modulo_dialogos/Hooks/useDialogo";
import Dialogo_Login from "./Dialogos/Dialogo_Login";
import {irUrl} from "../Utilidades/irUrl";

const Footer = () => {
    const navigate = useNavigate()
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const sTab = useMediaQuery(theme.breakpoints.only('sm'))
    const {Dialogo, abrir} = useDialogo({Componente: Dialogo_Login})

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Dialogo/>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', zIndex: 2}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    sx={{maxWidth: '1400px', px: 3}}
                >

                    <Grid item container lg={12} sm={12} xs={12}
                          sx={{justifyContent: 'center', marginTop: sCell ? 8 : 4}}>
                        <img src={logo} width={sCell ? 120 : sTab ? 140 : 210} height={'auto'}/>
                    </Grid>

                    <Grid item container lg={5} sm={6} xs={12} sx={{justifyContent: 'center', marginTop: 0, pt: 3}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            spacing={2}
                        >

                            {SECCIONES.map((item, index) => {
                                return (
                                    <ButtonBase key={`w${index}`}
                                                href={item === 'Catalogo' ? '' : `#${item}`}
                                                onClick={() => item === 'Catalogo' ? navigate(`/${item}`) : navigate('/')}
                                                sx={{
                                                    transition: 'all .2s ease-in-out',
                                                    borderBottom: 2, borderColor: '#ffffff00',
                                                    '&:hover': {borderColor: '#ffffff'}
                                                }}
                                    >
                                        <Typography
                                            sx={{fontSize: 18, fontWeight: 500, color: '#fff'}}>{item}</Typography>
                                    </ButtonBase>
                                )
                            })}

                            <ButtonBase
                                onClick={() => abrir()}

                                sx={{
                                    transition: 'all .2s ease-in-out',
                                    borderBottom: 2, borderColor: '#ffffff00',
                                    '&:hover': {borderColor: '#ffffff'},
                                    width: masSM ? 'auto' : '100%'
                                }}
                            >
                                <Typography
                                    sx={{fontSize: 18, fontWeight: 500, color: '#fff'}}>Administracion</Typography>
                            </ButtonBase>

                        </Grid>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12}
                          sx={{justifyContent: 'center', marginTop: masSM ? 7 : 2, zIndex: 1}}>
                        <Typography sx={{fontSize: 16, fontWeight: 700, color: '#fff', textAlign: 'center'}}>
                            Tienda Plaza de Nariño Cra 25# 18-45
                            <br/>
                            Tienda Hotel V1501 Calle 20# 33-50
                        </Typography>
                    </Grid>


                    <Grid item container lg={12} sm={12} xs={12}
                          sx={{justifyContent: 'center', marginTop: sCell ? 4 : 7}}>
                        <Typography sx={{fontSize: 16, fontWeight: 700, color: '#fff', textAlign: 'center'}}>
                            318 327 0023
                            <br/>
                            hola@narinosouvenirs.com
                            <br/>
                            personalizados-empresas@narinosouvenirs.com
                            <br/>
                            proveedores@narinosouvenirs.com
                        </Typography>
                    </Grid>


                    <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 3, pb: 1}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                        >

                            {REDES.map((item, index) => {
                                return (
                                    <Grid key={`iu${index}`} item container lg={1} sm={1} xs={2}
                                          sx={{justifyContent: 'center'}}>
                                        <IconButton onClick={() => irUrl(item.url)}>
                                            <item.icono sx={{fill: '#fff', width: 45, height: 45}}/>
                                        </IconButton>

                                    </Grid>
                                )
                            })}

                        </Grid>

                    </Grid>

                </Grid>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start', marginTop: sCell ? -56 : sTab ? -60 : -68}}>
                <img src={sCell ? fondo_cel : fondo} width={'100%'} height={'auto'}
                     style={{borderTopRightRadius: sCell ? 40 : 60, borderTopLeftRadius: sCell ? 40 : 60}}/>
            </Grid>

        </Grid>
    )

}
export default Footer    