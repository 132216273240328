/**************************************************
 * Nombre:       ExperienciaCultura
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import {theme} from "../../Tema";
import fondo from "../../Recursos/fondo_indicadores.svg";
import Tarjeta_indicador from "./Tarjeta_indicador";
import hojas from '../../Recursos/hojas.png'
import hojas_derecha from '../../Recursos/hojas_derecha.png'


const ExperienciaCultura = () => {
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >


                <Grid item container lg={10} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                        sx={{
                            backgroundColor: theme.palette.primary.main,
                            backgroundImage: `url(${fondo})`,
                            p: 6,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'center',
                            backgroundPosition: 'center'
                        }}
                        spacing={masSM ? 2 : 0}

                    >


                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                            <Typography sx={{
                                color: '#fff',
                                fontSize: 36,
                                fontWeight: 800,
                                lineHeight: 1.0,
                                textAlign: sCell ? 'center' : 'left'
                            }}>
                                Experiencia y Cultura
                            </Typography>
                        </Grid>


                        <Grid item container lg={12} sm={12} xs={12}
                              sx={{justifyContent: 'center', marginTop: masSM ? 0 : 2}}>
                            <Typography sx={{
                                color: '#fff',
                                fontSize: 18,
                                fontWeight: 400,
                                textAlign: masSM ? 'left' : 'center'
                            }}>
                                Trabajamos de la mano de artesanos y artistas de la región nariñense, somos tradición y
                                cultura
                            </Typography>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12}
                              sx={{justifyContent: 'center', marginTop: 4, zIndex: 2}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"

                            >

                                {indicadores.map((item, index) => {
                                    return (
                                        <Grid key={`er${index}`} item container lg={4} sm={4} xs={12} sx={{justifyContent: 'center'}}>
                                            <Tarjeta_indicador item={item}/>
                                        </Grid>
                                    )
                                })}

                            </Grid>
                        </Grid>


                        <Grid item container lg={12} sm={12} xs={12}
                              sx={{justifyContent: 'flex-start', zIndex: 1, marginTop: masSM ? 0 : 8}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                                    <img src={hojas_derecha} width={200} height={'auto'}
                                         style={{marginTop: -64, marginLeft: masSM ? -64 : -48, marginBottom: -48}}/>
                                </Grid>

                                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-end'}}>
                                    <img src={hojas} width={200} height={'auto'}
                                         style={{marginTop: -64, marginRight: -48, marginBottom: -48}}/>
                                </Grid>

                            </Grid>
                        </Grid>

                    </Grid>


                </Grid>

            </Grid>

        </Grid>
    )

}
export default ExperienciaCultura

const indicadores = [
    {
        nombre: 'Diseñadores(as), Artistas y Artesanos(as)',
        numero: 299
    },
    {
        nombre: 'Ventas desde 2022',
        numero: 9944
    },
    {
        nombre: 'Referencias de productos 100% nariñenses',
        numero: 800
    }
]