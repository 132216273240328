export const BODEGA = {
    codigo: "52001000",
    empresa: "Nariño Souvenirs",
    nombre: "Luis",
    apellido: "Ponce",
    correo: "hola@narinosouvenirs.com",
    celular: "3183270023",
    direccion: "Cra 25# 18-45",
    adicional: "Plaza Nariño",
    calle: "Carrera 25",
    referencia: " ",
    ciudad: "Pasto",
    cc: "901702737",
    departamento: "Nariño"

}