/**************************************************
 * Nombre:       Producto_Home
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, useMediaQuery} from "@mui/material";
import fondo from '../../Recursos/fondo_producto.png'
import Tarjeta_Producto_Detalle from "./Tarjeta_Producto_Detalle";
import {theme} from "../../Tema";
import {useEffect, useState} from "react";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";

const Producto_Home = () => {
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [producto, setProducto] = useState({})

    useEffect(() => {
        obtenerDoc('productoHome', 'productoHome').then((dox) => {
            if (dox.res) {
                setProducto(dox.data)
            }
        })
    }, [])
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{
                backgroundImage: `url(${fondo})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3, pb: sCell ? 24 : 4}}
            >

                <Grid item container lg={9} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <Tarjeta_Producto_Detalle item={producto}/>
                </Grid>

            </Grid>

        </Grid>
    )

}
export default Producto_Home