/**************************************************
 * Nombre:       Anuncios
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import {theme} from "../../Tema";
import fondo from '../../Recursos/fondo_anuncios.svg'
import personaje from '../../Recursos/personaje.png'
import {useEffect, useState} from "react";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import {irUrl} from "../../Utilidades/irUrl";

const Anuncios = () => {
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const sTab = useMediaQuery(theme.breakpoints.only('sm'))
    const [anuncio, setAnuncio] = useState({})

    useEffect(() => {
        obtenerDoc('anuncio', 'anuncio').then((dox) => {
            if (dox.res) {
                setAnuncio(dox.data)
            }
        })
    }, [])
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3,}}
            >

                <Grid item container lg={10} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                        sx={{
                            backgroundColor: theme.palette.primary.main,
                            backgroundImage: `url(${fondo})`,
                            p: sCell ? 2 : 6,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'center',
                            backgroundPosition: 'center',
                            pb: masSM ? 2 : 10
                        }}
                        spacing={masSM ? 2 : 0}

                    >

                        <Grid item container lg={5} sm={5} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <img src={anuncio.img} width={sCell ? '100%' : '150%'} height={'auto'}
                                 style={{
                                     marginLeft: sCell ? 0 : -200,
                                     marginTop: sCell ? -120 : -125,
                                     marginBottom: sCell ? 0 : -150
                                 }}/>
                        </Grid>

                        <Grid item container lg={6} sm={6} xs={12}
                              sx={{justifyContent: 'flex-end', marginTop: sCell ? 2 : 0}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                sx={{px: 2}}
                            >


                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                    <Typography sx={{
                                        color: '#fff',
                                        fontSize: 36,
                                        fontWeight: 800,
                                        lineHeight: 1.0,
                                        textAlign: sCell ? 'center' : 'left'
                                    }}>
                                        {anuncio.nombre}
                                    </Typography>
                                </Grid>


                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 2, zIndex: 2}}>
                                    <Typography sx={{
                                        color: '#fff',
                                        fontSize: 18,
                                        fontWeight: 400,
                                        textAlign: sCell ? 'center' : 'left',
                                        display: '-webkit-box',
                                        overflow: 'hidden',
                                        WebkitBoxOrient: 'vertical',
                                        WebkitLineClamp: sCell ? 3 : 2,
                                        textOverflow: "ellipsis",

                                    }}>
                                        {anuncio.descripcion}
                                    </Typography>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{
                                          justifyContent: sCell ? 'center' : 'flex-start',
                                          marginTop: sCell ? 8 : 4,
                                          marginBottom: anuncio && anuncio.link ? 2 : 7
                                      }}>
                                    {anuncio && anuncio.link &&
                                        <Button
                                            onClick={() => irUrl(anuncio.link)}
                                            color={'info'}
                                            sx={{color: theme.palette.primary.main}}
                                        >Más información</Button>
                                    }
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-end', zIndex: 1}}>
                                    {!sTab &&
                                        <img src={personaje} width={sCell ? 180 : 220} height={'auto'}
                                             style={{
                                                 marginBottom: sCell ? -80 : -16,
                                                 marginTop: sCell ? -80 : -170,
                                                 marginRight: sCell ? -32 : -108
                                             }}/>
                                    }
                                </Grid>

                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>


            </Grid>

        </Grid>
    )

}
export default Anuncios