/**************************************************
 * Nombre:       Tarjeta_testimonio
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Avatar, Grid, Typography} from "@mui/material";

const Tarjeta_testimonio = ({item}) => {

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{px: 8}}
        >

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <Avatar src={item.img}
                        sx={{width: 130, height: 130, border: 4, borderRadius: '50%', borderColor: '#DB8E3C'}}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                <Typography sx={{fontSize: 16, fontStyle: 'italic', textAlign: 'center'}}>
                    {`"${item.descripcion}"`}
                </Typography>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                <Typography sx={{fontWeight: 800, textAlign: 'center'}}>
                    {item.nombre}
                </Typography>
            </Grid>


        </Grid>
    )

}
export default Tarjeta_testimonio    