/**************************************************
 * Nombre:       PorQueComprar
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, IconButton, Typography, useMediaQuery} from "@mui/material";
import {theme} from "../../Tema";
import fondo from '../../Recursos/fondo_porque_comprar.svg'
import morenitos from '../../Recursos/morenitos.png'
import Tarjeta_Artesanos from "../Tarjetas/Tarjeta_Artesanos";
import {Splide, SplideSlide} from "@splidejs/react-splide";
import {useEffect, useState} from "react";
import {obtenerCol} from "../../Servicios/BD/obtenerCol";

const PorQueComprar = () => {
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const sTab = useMediaQuery(theme.breakpoints.only('sm'))
    const [artesanos, setArtesanos] = useState([])


    useEffect(() => {
        obtenerCol('artesanos').then((dox) => {
            if (dox.res) {
                setArtesanos(dox.data)
            }
        })
    }, [])
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"

        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: sCell ? 0 : 3}}
            >


                <Grid item container lg={10} sm={12} xs={12} sx={{justifyContent: 'flex-start', px: sCell ? 3.5 : 0}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            backgroundColor: theme.palette.primary.main,
                            p: 4,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundImage: `url(${fondo})`,
                            height: sCell ? 570 : 340,
                            zIndex: 1
                        }}
                    >


                        <Grid item container lg={12} sm={12} xs={12}
                              sx={{justifyContent: 'flex-end', zIndex: 1}}>
                            <img src={morenitos} width={240} height={'auto'}
                                 style={{
                                     marginTop: 114,
                                     marginBottom: sCell ? -265 : -32,
                                     marginRight: masSM ? -32 : -32
                                 }}/>
                        </Grid>


                    </Grid>
                </Grid>

                <Grid item container lg={11} sm={12} xs={12}
                      sx={{justifyContent: 'flex-start', marginTop: sCell ? -80 : -50, zIndex: 2}}>
                    <Splide aria-label="My Favorite Images" style={{width: '100%'}}
                            options={{
                                autoplay: true,
                                speed: 1500,
                                rewind: true,
                                perPage: 1,
                                arrows: true,
                                pagination: false,
                                width: '100%',
                                drag: 'free',
                                type: 'fade',
                                interval: 8000
                            }}
                    >

                        {artesanos.map((item, index) => {
                            return (
                                <SplideSlide key={`qe${index}`} >
                                    <Tarjeta_Artesanos item={item}/>
                                </SplideSlide>
                            )
                        })}


                    </Splide>

                </Grid>


            </Grid>


        </Grid>
    )

}
export default PorQueComprar

