/**************************************************
 * Nombre:       Lista_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Box, Button, Grid, ImageList, ImageListItem, ImageListItemBar, Typography, useMediaQuery} from "@mui/material";
import {theme} from "../../Tema";
import {formatoMoneda} from "../../Utilidades/formatoMoneda";
import {useNavigate} from "react-router-dom";

const Lista_Productos = ({data}) => {
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const navigate = useNavigate()

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item container lg={9} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <ImageList variant="masonry" cols={sCell ? 2 : 3} gap={sCell ? 10 : 20}>
                        {data.map((item, index) => (
                            <ImageListItem
                                onClick={() => navigate('/Detalle/' + item.id)}
                                key={`lsd${index}`}
                                sx={{
                                backgroundColor: '#F1F1F1', p: sCell ? 1 : 2,
                                transition: 'all .2s ease-in-out',
                                "&:hover": {boxShadow: 8},
                                cursor: 'pointer'
                            }}>

                                {item.descuento > 0 &&
                                    <Grid item container lg={12} sm={12} xs={12}
                                          sx={{
                                              justifyContent: 'flex-start',
                                              marginLeft: sCell ? -1 : -2,
                                              marginBottom: -4
                                          }}>

                                        <Box sx={{
                                            backgroundColor: theme.palette.primary.main,
                                            px: sCell ? 1 : 2,
                                            py: sCell ? 0.5 : 1,
                                            borderTopRightRadius: 10,
                                            borderBottomRightRadius: 10
                                        }}>
                                            <Typography
                                                sx={{color: '#fff', fontSize: sCell ? 14 : 18, fontWeight: 500}}>
                                                {`- ${item.descuento}%`}
                                            </Typography>
                                        </Box>

                                    </Grid>
                                }

                                <img
                                    srcSet={item.formato === '1:1' ? `${item.img}?w=248&fit=crop&auto=format&dpr=2 2x` : `${item.img_3x2}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                    src={item.formato === '1:1' ? `${item.img}?w=248&fit=crop&auto=format` : `${item.img_3x2}?w=248&fit=crop&auto=format`}
                                    alt={item.nombre}
                                    loading="lazy"
                                />

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    sx={{p: sCell ? 1 : 2}}
                                >

                                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                        <Typography
                                            sx={{
                                                color: theme.palette.secondary.main,
                                                fontSize: sCell ? 18 : 24,
                                                fontWeight: 700,
                                                lineHeight: 1.1
                                            }}>
                                            {item.nombre}
                                        </Typography>
                                    </Grid>

                                    <Grid item container lg={12} sm={12} xs={12}
                                          sx={{justifyContent: 'flex-start', marginTop: 1}}>
                                        {item.artesano &&
                                            <Typography
                                                sx={{fontSize: sCell ? 10 : 16, fontWeight: 300, lineHeight: 1.1}}>
                                                {`creado por: @${item.artesano}`}
                                            </Typography>
                                        }
                                    </Grid>

                                    <Grid item container lg={6} sm={12} xs={12}
                                          sx={{justifyContent: 'flex-start', marginTop: 1}}>
                                        <Typography
                                            sx={{
                                                color: theme.palette.primary.main,
                                                fontSize: sCell ? 18 : 26,
                                                fontWeight: 700
                                            }}>
                                            {item.descuento > 0 ? formatoMoneda(item.rebajado) : formatoMoneda(item.valor)}
                                        </Typography>
                                    </Grid>


                                </Grid>
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Grid>

            </Grid>


        </Grid>
    )

}
export default Lista_Productos
