/**************************************************
 * Nombre:       Categorias_Catalogo
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography, useMediaQuery} from "@mui/material";
import fondo from "../../Recursos/fondo_producto.png";
import Tarjeta_Producto_Detalle from "./Tarjeta_Producto_Detalle";
import {theme} from "../../Tema";
import {Splide, SplideSlide} from "@splidejs/react-splide";
import '@splidejs/react-splide/css';
import Tarjeta_Categoria_Cuadrada from "./Tarjeta_Categoria_Cuadrada";
import {useEffect, useState} from "react";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";

const Categorias_Catalogo = ({categorias, click}) => {
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [descripcion, setDescripcion] = useState('')

    useEffect(() => {
        obtenerDoc('textos', 'descripcionCatalogo').then((dox) => {
            if (dox.res) {
                setDescripcion(dox.data.descripcion)
            }
        })
    }, [])
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{
                backgroundImage: `url(${fondo})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                maxWidth: '100%'
            }}
        >


            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3, pb: 35}}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 8}}>
                    <Typography sx={{
                        color: theme.palette.secondary.main,
                        fontSize: masSM ? 42 : 36,
                        fontWeight: 700,
                        textAlign: 'center',
                        lineHeight: 1.1
                    }}>Nuestro
                        catálogo para tí</Typography>
                </Grid>

                <Grid item container lg={5} sm={8} xs={12} sx={{justifyContent: 'center', marginTop: sCell ? 2 : 0}}>
                    <Typography sx={{fontSize: 16, fontWeight: 300, textAlign: 'center'}}>

                        {descripcion}
                    </Typography>
                </Grid>

                <Grid item container lg={10} sm={12} xs={12}
                      sx={{justifyContent: 'flex-start', marginTop: 6, maxWidth: '100vw'}}>

                    <Splide aria-label="My Favorite Images Two" style={{width: '100%'}}
                            options={{
                                autoplay: false,
                                speed: 3000,
                                rewind: true,
                                perPage: sCell ? 2 : 4,
                                arrows: true,
                                gap: masSM ? 40 : 20,
                                pagination: false,
                                drag: 'free',
                                width: '100%'
                            }}
                    >
                        {categorias.map((item, index) => {
                            return (
                                <SplideSlide key={`tr${index}`}>
                                    <Tarjeta_Categoria_Cuadrada item={item} click={click}/>
                                </SplideSlide>
                            )
                        })}


                    </Splide>


                </Grid>

            </Grid>

        </Grid>
    )

}
export default Categorias_Catalogo

