import axios from "axios";

export const generarGuia = ({bodega, valorTotal, pedido}) => {


    let data = JSON.stringify({
        "idRate": pedido.idCarrier,
        "myShipmentReference": pedido.id,
        "requestPickup": false,
        "pickupDate": "2020-11-24",
        "insurance": true,
        "description": "Pedido Nariño Souvenirs",
        "contentValue": valorTotal,
        "includeGuideCost": false,
        "packages": [
            {
                "weight": pedido.pesoTotal,
                "height": pedido.dimencionTotal.alto,
                "width": pedido.dimencionTotal.ancho,
                "length": pedido.dimencionTotal.fondo
            }
        ],
        "origin": {
            "company": bodega.empresa,
            "firstName": bodega.nombre,
            "lastName": bodega.apellido,
            "email": bodega.correo,
            "phone": bodega.celular,
            "address": bodega.direccion,
            "suburb": bodega.adicional,
            "crossStreet": bodega.calle,
            "reference": bodega.referencia,
            "daneCode": bodega.codigo
        },
        "destination": {
            //  "company": pedido.nombre.substr(0, pedido.nombre.length > 28 ? 27 : pedido.nombre.length),
            "firstName": pedido.nombre.substr(0, pedido.nombre.length > 14 ? 13 : pedido.nombre.length),
            "lastName": pedido.apellido.substr(0, pedido.apellido.length > 14 ? 13 : pedido.apellido.length),
            "email": pedido.correo,
            "phone": pedido.celular.replaceAll(' ', ''),
            "address": pedido.direccion.substr(0, pedido.direccion.length > 50 ? 49 : pedido.direccion.length),
            "suburb": pedido.barrio,
            "crossStreet": '',
            "reference": pedido.indicaciones.substr(0, pedido.indicaciones.length > 25 ? 24 : pedido.indicaciones.length),
            "daneCode": pedido.codigoDane + '000'
        }
    });

    /*
    *
    * "company": "Luis M Rosero Desarrollador",
            "firstName": "Luis",
            "lastName": "Rosero",
            "email": "luismrosero1992@gmail.com",
            "phone": "3004627579",
            "address": "Mz 9 Cs 8 ",
            "suburb": "Pinares de Santa Ana",
            "crossStreet": "Carrera 3",
            "reference": "Casa esquinera",
            "daneCode": "52356000"
    *
    *
    *
    * */

    let config = {
        method: 'post',
        url: 'https://api.envioclickpro.com.co/api/v2/shipment',
        headers: {
            'Authorization': 'c026d2d9-9cb3-4d3a-9969-52afc0adb9b9',
            'Content-Type': 'application/json'
        },
        data: data
    };


    return new Promise((resolve) => {
        axios(config)
            .then(function (response) {
                if (response.status === 200) {
                    return resolve({res: true, data: response.data.data})
                } else {
                    return resolve({res: false, data: "error interno"})
                }
            })
            .catch(function (error) {
                console.log(error.response.data.status_messages)
                return resolve({res: false, data: error.message})
            });

    })

}